
*:focus{outline:none !important
}
.menu-container{position:fixed;padding:0;margin:0;top:0;left:0;bottom:0;right:0;background-color:#072635;z-index:999;opacity:0;visibility:hidden;transition-duration:.5s;overflow:hidden;max-height:100vh
}
.menu-container .nav-wrapper{width:90%;max-width:1600px;padding-top:120px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 768px) and (max-width : 1023px){
.menu-container .nav-wrapper{padding-top:40px
}
}
.menu-container .little-m{transition:.3s;width:140px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.menu-container .little-m{width:140px
}
}
.menu-container .cross{cursor:pointer;width:25px;float:right;margin-right:10px;margin-top:15px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.menu-container .cross{width:15px;margin-top:10px
}
}
.menu-container .projects-header-container{display:block;box-sizing:border-box;width:90%;max-width:1600px;opacity:0;transition:opacity 2s ease;margin:0 auto;min-height:83vh
}
@media only screen and (min-width: 1800px){
.menu-container .projects-header-container{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.menu-container .projects-header-container{margin-top:0
}
}
.menu-container .projects-header-container .nav-container-menu{display:block;margin-top:60px
}
@media only screen and (min-width: 1800px){
.menu-container .projects-header-container .nav-container-menu{margin-top:80px
}
}
.menu-container .projects-header-container .nav-container-menu ul{padding:0;margin:0;color:white
}
.menu-container .projects-header-container .nav-container-menu ul>li{margin-bottom:30px;list-style:none;cursor:pointer;color:#F7F7F7;font-family:Roboto,Helvetica,Arial,sans-serif;transition-duration:.8s
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.menu-container .projects-header-container .nav-container-menu ul>li{margin-bottom:15px
}
}
@media only screen and (min-width: 1800px){
.menu-container .projects-header-container .nav-container-menu ul>li{margin-bottom:30px
}
}
.menu-container .projects-header-container .nav-container-menu ul>li h3{color:white;margin-top:0px;margin-bottom:0px;transition:color .2s ease
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.menu-container .projects-header-container .nav-container-menu ul>li h3{font-size:35px
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
.menu-container .projects-header-container .nav-container-menu ul>li h3{font-size:45px
}
}
@media only screen and (min-width: 1800px){
.menu-container .projects-header-container .nav-container-menu ul>li h3{font-size:45px
}
}
.menu-container .projects-header-container .nav-container-menu ul>li h3:hover{color:#72BF00
}
@media only screen and (max-width : 392px){
.menu-container .projects-header-container .nav-container-menu ul>li h3{font-size:24px;padding-top:10px
}
}
@media only screen and (min-width : 393px) and (max-width : 659px){
.menu-container .projects-header-container .nav-container-menu ul>li h3{font-size:26px;padding-top:10px;padding-bottom:10px
}
}
.menu-container .projects-header-container .nav-container-menu ul>li a{color:#F7F7F7
}
.menu-container .projects-header-container .nav-container-menu ul>li a:hover{text-decoration:none
}
.menu-container .projects-header-container ul>li:nth-child(1){transition-delay:.1s
}
.menu-container .projects-header-container ul>li:nth-child(2){transition-delay:.2s
}
.menu-container .projects-header-container ul>li:nth-child(3){transition-delay:.3s
}
.menu-container .projects-header-container ul>li:nth-child(4){transition-delay:.4s
}
.menu-container .projects-header-container ul>li:nth-child(5){transition-delay:.5s
}
.menu-container .projects-header-container ul>li:nth-child(6){transition-delay:.6s
}
.menu-container .projects-header-container .contact-info{margin-top:60px;padding-left:5vw
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.menu-container .projects-header-container .contact-info{display:none
}
}
@media only screen and (min-width: 1800px){
.menu-container .projects-header-container .contact-info{margin-top:80px
}
}
.menu-container .projects-header-container .contact-info p{transition-duration:.8s;line-height:23px;font-size:20px;font-family:"Lato-Regular" !important;font-size:1.125rem;color:#171519
}
.menu-container .projects-header-container .contact-info p .city-span{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.menu-container .projects-header-container .contact-info p{font-size:1rem;line-height:1.4rem
}
}
.menu-container .projects-header-container .contact-info .got-a-project-wrapper{transition-duration:.8s;transition-delay:.4s;margin-top:80px
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.menu-container .projects-header-container .contact-info .got-a-project-wrapper{margin-top:50px
}
}
@media only screen and (min-width: 1800px){
.menu-container .projects-header-container .contact-info .got-a-project-wrapper{margin-top:8vh
}
}
.menu-container .projects-header-container .contact-info .got-a-project-wrapper a:hover{text-decoration:none
}
.menu-container .projects-header-container .contact-info .got-a-project-wrapper .lets-talk{font-family:Roboto,Helvetica,Arial,sans-serif;color:#171519;font-size:32px
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.menu-container .projects-header-container .contact-info .got-a-project-wrapper .lets-talk{font-size:22px
}
}
.menu-container .projects-header-container .contact-info .got-a-project-wrapper .arrow-right{color:#72BF00
}
.menu-container .projects-header-container .menu-footer{transition:.8s;transition-delay:.7s;margin-top:30px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.menu-container .projects-header-container .menu-footer{display:none
}
}
@media only screen and (min-width: 1800px){
.menu-container .projects-header-container .menu-footer{margin-top:3vh
}
}
.menu-container .year{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:13px;line-height:13px;padding-left:10px
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.menu-container .year{line-height:unset
}
}
.menu-container .spaces-logo{padding:15px 0px!important
}
@media only screen and (min-width: 1800px){
.menu-container .spaces-logo-container-mobile{display:none
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
.menu-container .spaces-logo-container-mobile{display:none
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.menu-container .spaces-logo-container-mobile{display:none
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.menu-container .spaces-logo-container-mobile{display:none
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.menu-container .spaces-logo-container-mobile{display:none
}
}
@media only screen and (min-width : 393px) and (max-width : 659px){
.menu-container .spaces-logo-container-mobile{display:none
}
}