
.overlay-bottom[data-v-30ce9bdd]{position:fixed;bottom:0;left:0;height:0;width:100vw;background:var(--overlay-bg);transition-duration:var(--transition-duration)
}
.overlay-up-enter~.overlay-bottom[data-v-30ce9bdd],.overlay-up-leave-to~.overlay-bottom[data-v-30ce9bdd]{height:0
}
.overlay-up-enter-active~.overlay-bottom[data-v-30ce9bdd],.overlay-up-leave-active~.overlay-bottom[data-v-30ce9bdd]{height:100vh
}
.overlay-up-enter-active~.overlay-bottom[data-v-30ce9bdd]{transition-timing-function:ease-in
}
.overlay-up-leave-active~.overlay-bottom[data-v-30ce9bdd]{transition-timing-function:ease-out
}
.overlay-up-enter-active[data-v-30ce9bdd],.overlay-up-leave-active[data-v-30ce9bdd]{transition-duration:var(--transition-duration)
}
.fade-enter-active[data-v-30ce9bdd],.fade-leave-active[data-v-30ce9bdd]{transition-duration:.3s;transition-property:opacity;transition-timing-function:ease
}
.fade-enter[data-v-30ce9bdd],.fade-leave-active[data-v-30ce9bdd]{opacity:0
}