
*[data-v-aeaac142]:focus{outline:none !important
}
.cursor[data-v-aeaac142]{pointer-events:none
}
.cursor-ball[data-v-aeaac142]{position:fixed;top:0;left:0;mix-blend-mode:difference;z-index:999999999;opacity:0;transition:opacity .5s ease
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.cursor-ball[data-v-aeaac142]{display:none
}
}
.cursor-ball circle[data-v-aeaac142]{fill:#f7f8fa
}