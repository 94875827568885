
*:focus{outline:none !important
}
.video-wrapper{width:100%;height:100%;position:relative;overflow:hidden;background:black
}
.video-wrapper .ar-text{padding-left:5vw
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px){
.video-wrapper .ar-text{padding-left:0
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px){
.video-wrapper .ar-text{padding-top:0;padding-bottom:10vh !important
}
}
.video-wrapper .video-container{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:end;justify-content:flex-end
}
.video-wrapper .project-one-container-new{margin:0 auto !important;width:90%;background:black;padding-bottom:30px;color:white
}
.video-wrapper .project-one-container-new p{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:34px;font-weight:300;line-height:44px;max-width:50%
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.video-wrapper .project-one-container-new p{max-width:95%;font-size:24px;line-height:34px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.video-wrapper{padding-top:5vh;margin-bottom:0px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.video-wrapper{padding-top:0
}
}
.video-wrapper .projects-video{width:100% !important
}
.video-wrapper .projects-video video{width:100% !important
}