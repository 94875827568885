
*[data-v-05f532b8]:focus{outline:none !important
}
.logo-burger[data-v-05f532b8]{width:95%;margin:0 auto;padding-top:3%;max-width:1800px
}
.logo-burger .hamburger[data-v-05f532b8]{margin-right:10px;margin-top:5px;z-index:12;cursor:pointer;display:inline-block;float:right
}
@media only screen and (min-width: 1800px){
.logo-burger .hamburger img[data-v-05f532b8]{width:35px
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
.logo-burger .hamburger img[data-v-05f532b8]{width:28px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.logo-burger .hamburger img[data-v-05f532b8]{width:25px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 393px) and (max-width : 659px){
.logo-burger .hamburger img[data-v-05f532b8]{width:23px
}
}
.logo-burger .mlogo[data-v-05f532b8]{width:auto;cursor:pointer;display:inline-block
}
@media only screen and (min-width: 1800px){
.logo-burger .mlogo[data-v-05f532b8]{max-height:50px
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
.logo-burger .mlogo[data-v-05f532b8]{max-height:50px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.logo-burger .mlogo[data-v-05f532b8]{max-height:40px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 393px) and (max-width : 659px){
.logo-burger .mlogo[data-v-05f532b8]{max-height:40px
}
}