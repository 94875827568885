
*:focus{outline:none !important
}
.custom-col{padding-left:0;margin-bottom:40px
}
@media only screen and (max-width : 392px){
.custom-col{margin-bottom:10px
}
}
.footer-container-new{width:88.5%;max-width:1800px
}
@media only screen and (max-width : 392px){
.footer-container-new{width:90% !important;padding:5px !important
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.footer-container-new .col-6{width:100% !important;max-width:100% !important
}
}
@media only screen and (max-width : 392px){
.footer-container-new .mobile{padding-bottom:10px;-ms-flex:0 0 100% !important;flex:0 0 100% !important
}
}
.footer-container-new .stay-in-loop{padding-left:0
}
@media only screen and (max-width : 392px){
.footer-container-new .stay-in-loop{padding-top:35px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.footer-container-new .stay-in-loop{padding-top:20px
}
}
@media only screen and (max-width : 392px){
.footer-container-new .footer-bottom{margin-top:0px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.footer-container-new .footer-bottom .col-8{max-width:100%;-ms-flex:0 0 100% !important;flex:0 0 100% !important;padding-bottom:35px
}
}
.opaque{opacity:0;-ms-transform:translateY(200px);transform:translateY(200px)
}
a:hover{text-decoration:none
}
#addresses{margin:0 auto
}
.address-header{font-size:14px;font-family:Roboto,Helvetica,Arial,sans-serif;line-height:23px
}
.address{font-size:19px;line-height:23px;font-family:Roboto,Helvetica,Arial,sans-serif;color:#5B5B5B
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.address{font-size:14px;line-height:21px
}
}
.footer-info-description{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#171519;margin-bottom:5px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.footer-info-description{font-size:12px
}
}
.footer-info-description-text{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#171519
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.footer-info-description-text{font-size:12px
}
}
.footer-info-header{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:16px;line-height:23px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.footer-info-header{font-size:14px
}
}
#social-media{display:-ms-flexbox;display:flex
}
#social-media .item{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:13px;margin-right:15px;color:#5B5B5B
}
#social-media .item .link{color:#5B5B5B
}
.copyright{color:#5B5B5B;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:13px;display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end;padding-left:20px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.copyright{-ms-flex-pack:center;justify-content:center
}
}
.upperCase{text-transform:uppercase
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.col-5{width:100% !important;max-width:100% !important
}
}
.mirk-home-link p{font-size:1.2vw;font-weight:bold;color:black;text-decoration:underline
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.logos-container{-ms-flex-direction:row !important;flex-direction:row !important
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.logos-container{-ms-flex-direction:column !important;flex-direction:column !important;-ms-flex-pack:center;justify-content:center
}
}
.logos-container .logos-images{display:-ms-flexbox;display:flex;width:100%;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;padding-left:0px !important
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.logos-container .logos-images{padding:20px 0px;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column
}
}
.logos-container .logos-images .footer-logo-container{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center
}
.logos-container .logos-images .spaces-image{width:200px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.logos-container .logos-images .spaces-image{width:200px;margin-bottom:20px
}
}
.logos-container .logos-images .image{width:250px
}
@media only screen and (max-width : 392px){
.logos-container .logos-images .image{width:100%
}
}
.privacy-policy-link{color:#072635;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:end;align-items:flex-end;-ms-flex-item-align:revert;align-self:revert;line-height:30px
}
.privacy-policy-link .item{border-bottom:1px solid #072635
}