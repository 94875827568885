
*[data-v-f3211f0a]:focus{outline:none !important
}
.client-logos-container-new[data-v-f3211f0a]{position:relative;width:100%;padding:100px 0;color:#FFFFFF
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.client-logos-container-new .col-6[data-v-f3211f0a]{padding-left:0;padding-right:0;text-align:center
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.client-logos-container-new[data-v-f3211f0a]{padding:50px 10px;min-height:50vh
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.client-logos-container-new[data-v-f3211f0a]{padding:0px 10px;min-height:50vh
}
}
.client-logos-container-new .logos-wrapper[data-v-f3211f0a]{width:90%;max-width:1600px;margin:0px auto;padding-left:5%
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.client-logos-container-new .logos-wrapper[data-v-f3211f0a]{padding-left:0px;width:90%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.client-logos-container-new .logos-wrapper[data-v-f3211f0a]{display:none;width:90%;padding-left:3%;padding-right:3%
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.client-logos-container-new .logos-wrapper[data-v-f3211f0a]{padding-left:0;width:90%
}
}
@media only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.client-logos-container-new .logos-wrapper[data-v-f3211f0a]{width:90%
}
}
.client-logos-container-new .logo-item[data-v-f3211f0a]{max-height:140px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.client-logos-container-new .logo-item[data-v-f3211f0a]{max-height:100px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.client-logos-container-new .logo-item[data-v-f3211f0a]{max-height:140px
}
}
.client-logos-container-new-custom[data-v-f3211f0a]{display:block;width:100%;padding:180px 0;color:#FFFFFF
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.client-logos-container-new-custom .col-6[data-v-f3211f0a]{padding-left:0;padding-right:0;text-align:center
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.client-logos-container-new-custom[data-v-f3211f0a]{padding:0px 10px;padding-top:3rem
}
}
.client-logos-container-new-custom .logos-wrapper[data-v-f3211f0a]{padding-left:10%;padding-right:5%;width:90%;max-width:1600px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.client-logos-container-new-custom .logos-wrapper[data-v-f3211f0a]{width:90%;padding-left:3%;padding-right:3%
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.client-logos-container-new-custom .logos-wrapper[data-v-f3211f0a]{width:90%
}
}
.client-logo-wrapper-mobile[data-v-f3211f0a]{min-height:45vh;padding-top:10vh
}
@media only screen and (min-width : 1024px) and (max-width: 1799px),only screen and (min-width: 1800px),only screen and (min-width : 660px) and (max-width : 1023px){
.client-logo-wrapper-mobile[data-v-f3211f0a]{display:none
}
}
.client-logo-mobile-img[data-v-f3211f0a]{margin-top:70vh;width:100vw
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.client-logo-mobile-img[data-v-f3211f0a]{margin-top:75vh
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
#client-logo-mobile-1[data-v-f3211f0a],#client-logo-mobile-2[data-v-f3211f0a]{position:absolute;top:0;left:0
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
#client-logo-mobile-1[data-v-f3211f0a]{opacity:0
}
}
.trusted-by[data-v-f3211f0a]{color:#072635;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;margin-top:50px;margin-bottom:50px;max-width:500px
}
.partner-logos img[data-v-f3211f0a]{object-fit:contain;max-width:100%
}
.section-title[data-v-f3211f0a]{font-weight:700 !important;font-size:55px;line-height:65px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.section-title[data-v-f3211f0a]{font-size:32px;line-height:47px
}
}