
*[data-v-798b1466]:focus{outline:none !important
}
.process-wrapper[data-v-798b1466]{background:white
}
.process-wrapper .process-content[data-v-798b1466]{padding-top:10vh;padding-bottom:10vh;width:90%;max-width:1600px;margin:0 auto;padding-left:5% !important
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.process-wrapper .process-content[data-v-798b1466]{width:90%;padding-left:0 !important
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px),only screen and (min-width : 1024px) and (max-width : 1365px){
.process-wrapper .process-content[data-v-798b1466]{width:90%;padding-left:0 !important
}
}
.process-wrapper .process-content h1[data-v-798b1466]{color:#072635;width:100%;font-size:55px;line-height:65px;font-family:Roboto,Helvetica,Arial,sans-serif;font-weight:700;margin-top:5vh
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.process-wrapper .process-content h1[data-v-798b1466]{font-size:32px;line-height:47px
}
}
.process-wrapper .process-content p[data-v-798b1466]{color:#072635;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;max-width:580px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.process-wrapper .process-content p[data-v-798b1466]{margin-bottom:5vh
}
}
.process-wrapper .process-content img[data-v-798b1466]{max-width:100%;object-fit:cover
}
.process-wrapper .process-content .process-logos[data-v-798b1466]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.process-wrapper .process-content .process-logos[data-v-798b1466]{display:none
}
}
.process-wrapper .process-content .process-logos-mob[data-v-798b1466]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (min-width : 1024px) and (max-width: 1799px),only screen and (min-width: 1800px),only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px){
.process-wrapper .process-content .process-logos-mob[data-v-798b1466]{display:none
}
}