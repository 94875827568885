
*:focus{outline:none !important
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.no-padding{padding-left:0px;padding-right:0px
}
}
.fadeInDown{background-color:black
}
.hide-bar{overflow:hidden
}
body{margin:0;font-size:2rem;font-family:Roboto,Helvetica,Arial,sans-serif;-webkit-font-smoothing:antialiased;-moz-font-smoothing:antialiased;-o-font-smoothing:antialiased;cursor:none
}
h1,h3{font-family:Roboto,Helvetica,Arial,sans-serif;font-weight:300;font-size:3.2rem;color:#072635
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
h3{font-size:28px
}
}
h2{font-size:4vw;line-height:4.3vw;font-weight:300;font-family:Roboto,Helvetica,Arial,sans-serif
}
@media only screen and (min-width: 1800px){
h2{font-size:3.3vw;line-height:3.6vw
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
h2{font-size:3.5vw;line-height:3.8vw
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
h2{font-size:4.1vw;line-height:4.4vw
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
h2{font-size:9vw;line-height:10vw
}
}
p{font-size:1.6vw;line-height:2.2vw;font-family:Roboto,Helvetica,Arial,sans-serif
}
@media only screen and (min-width: 1800px){
p{font-size:20px;line-height:30px
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
p{font-size:1.4vw;line-height:1.8vw
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
p{font-size:2vw;line-height:2.4vw
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
p{font-size:20px;line-height:30px
}
}
a{text-decoration:none !important
}
tspan{font-family:Roboto,Helvetica,Arial,sans-serif
}
.page{text-align:center
}
.app-container{opacity:1;background-color:#FFFFFF;height:100%;min-height:100vh
}
.clearfix{zoom:1
}
.clearfix:before,.clearfix:after{content:"";display:table
}
.clearfix:after{clear:both
}
.services-item-wrapper{overflow-y:hidden !important;overflow-x:hidden !important
}
@keyframes fadeInUp{
from{transform:translate3d(0, 40px, 0)
}
to{transform:translate3d(0, 0, 0);opacity:1
}
}
.animated{animation-duration:1.5s;animation-fill-mode:both;-webkit-animation-duration:1.5s;-webkit-animation-fill-mode:both
}
.animatedFadeInUp{opacity:0
}
.fadeInUp{opacity:0;animation-name:fadeInUp;-webkit-animation-name:fadeInUp
}
.blocker{position:absolute;height:100%;width:100%;z-index:10
}
.blocker:hover .imgbox{background-color:blue
}
.blocker:hover .imgbox div{-ms-transform:scale(1.1);transform:scale(1.1)
}
html{scroll-behavior:smooth
}
.carousel-control-next-icon,.carousel-control-prev-icon{width:50px;height:50px;opacity:1 !important
}
.custom-control-label{width:100%
}
.custom-control-label::before{top:1.25rem !important
}
.custom-control-label::after{top:1.25rem !important
}