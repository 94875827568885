
*[data-v-57bf5f11]:focus{outline:none !important
}
#home-cont[data-v-57bf5f11]{transition:opacity 1s;-webkit-transition:opacity 1s;-moz-transition:opacity 1s;-o-transition:opacity 1s
}
#home-cont .services-listing-container[data-v-57bf5f11]{background:#072635
}
#lottie-cont[data-v-57bf5f11]{transition:opacity 1s;-webkit-transition:opacity 1s;-moz-transition:opacity 1s;-o-transition:opacity 1s;opacity:1;z-index:70
}
#configurator[data-v-57bf5f11]{max-width:100vw
}