
*:focus{outline:none !important
}
.image-audience{opacity:0;-ms-transform:translateY(100px);transform:translateY(100px)
}
.image-local{opacity:0;-ms-transform:translateY(100px);transform:translateY(100px)
}
.image-getting-started{opacity:0;-ms-transform:translateY(100px);transform:translateY(100px)
}
.pointer{cursor:pointer
}
.banner-section-container{padding:2vh 0px;min-height:650px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.banner-section-container{padding-left:7.5% !important
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.banner-section-container{padding-left:0 !important
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-section-container{padding-left:0 !important
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.banner-section-container{padding-left:0!important
}
}
.banner-section-container .div-row{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-section-container .div-row{display:-ms-flexbox;display:flex;-ms-flex-direction:column-reverse;flex-direction:column-reverse;padding-right:0
}
}
.banner-section-container .div-row-reverse{display:-ms-flexbox;display:flex;-ms-flex-direction:row-reverse;flex-direction:row-reverse;padding-left:5%
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-section-container .div-row-reverse{display:-ms-flexbox;display:flex;-ms-flex-direction:column-reverse;flex-direction:column-reverse;padding-right:0;padding-left:0
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px),only screen and (min-width : 1024px) and (max-width : 1365px){
.banner-section-container .div-row-reverse{padding-left:0
}
}
.banner-section-container .banner-image{width:100%;object-fit:cover
}
.banner-section-container .title{color:white;width:100%;font-size:55px;font-family:Roboto,Helvetica,Arial,sans-serif
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.banner-section-container .title{padding-top:20px;font-size:32px;line-height:47px
}
}
.banner-section-container .description{color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;max-width:85%
}
@media only screen and (min-width : 393px) and (max-width : 659px),only screen and (max-width : 392px){
.banner-section-container .description{max-width:90%
}
}
.banner-section-container .list{color:black;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:16.61px;line-height:30px
}
.banner-section-container .project-image-container{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;padding-right:50px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-section-container .project-image-container{padding-right:10px;padding-left:0
}
}
.banner-section-container .project-info{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;text-align:left;padding-left:5px;padding:9vh 0
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-section-container .project-info{padding:5vh 0
}
}
.banner-section-container .project-info ul{list-style-position:outside
}
.banner-section-container .project-info li{list-style:none;text-indent:-25px
}
@media only screen and (max-width : 392px){
.banner-section-container .project-info li{max-width:90%;margin-bottom:10px
}
}
.banner-section-container .project-info li:before{content:'';background-color:#072635;border:1px solid #072635;display:inline-block;position:relative;height:10px;width:10px;margin-right:10px;bottom:2px;border-radius:5px
}
.banner-section-container .title{font-weight:700
}
.banner-wrapper{width:90%;max-width:1600px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-wrapper{width:90%
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px),only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.banner-wrapper{width:90%
}
}