
*:focus{outline:none !important
}
.custom-col{padding-left:0;margin-bottom:40px
}
@media only screen and (max-width : 392px){
.custom-col{margin-bottom:10px
}
}
@media only screen and (max-width : 392px){
.footer-container-contact{padding:15px !important
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.footer-container-contact .col-6{width:100% !important;max-width:100% !important
}
}
@media only screen and (max-width : 392px){
.footer-container-contact .mobile{padding-bottom:10px;-ms-flex:0 0 100% !important;flex:0 0 100% !important
}
}
.footer-container-contact .stay-in-loop{padding-left:0
}
@media only screen and (max-width : 392px){
.footer-container-contact .stay-in-loop{padding-top:35px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.footer-container-contact .stay-in-loop{padding-top:20px
}
}
.footer-container-contact .footer-bottom{margin-top:50px
}
@media only screen and (max-width : 392px){
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.footer-container-contact .footer-bottom .col-8{max-width:100%;-ms-flex:0 0 100% !important;flex:0 0 100% !important;padding-bottom:35px
}
}
.opaque{opacity:0;-ms-transform:translateY(200px);transform:translateY(200px)
}
a:hover{text-decoration:none
}
.footer-container-new{width:92%;background-color:white;box-sizing:border-box;padding:30px 0 30px 0;margin:0 auto
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.footer-container-new{width:90%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
}
#addresses{margin:0 auto
}
.address-header{font-size:14px;font-family:Roboto,Helvetica,Arial,sans-serif;line-height:23px
}
.address{font-size:19px;line-height:23px;font-family:Roboto,Helvetica,Arial,sans-serif;color:#5B5B5B
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.address{font-size:14px;line-height:21px
}
}
.footer-info-description{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#171519;margin-bottom:5px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.footer-info-description{font-size:12px
}
}
.footer-info-description-text{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#171519
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.footer-info-description-text{font-size:12px
}
}
.footer-info-header{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:16px;line-height:23px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.footer-info-header{font-size:14px
}
}
#social-media{display:-ms-flexbox;display:flex
}
#social-media .item{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:13px;margin-right:15px;color:#5B5B5B
}
#social-media .item .link{color:#5B5B5B
}
.copyright{color:#5B5B5B;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:13px;display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.copyright{padding-left:15px !important;-ms-flex-pack:center;justify-content:center
}
}
.upperCase{text-transform:uppercase
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.col-5{width:100% !important;max-width:100% !important
}
}
.mirk-home-link p{font-size:1.2vw;font-weight:bold;color:black;text-decoration:underline
}
.contact-form-container{padding-left:5% !important;background-color:#E0F0C7
}
.contact-form-container .wrapper{width:90%;max-width:1600px;margin:0px auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-container .wrapper{width:100%
}
}
.contact-form-container .section{margin:100px auto;padding:120px 0px 120px 0px;max-width:700px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-container .section{margin:70px auto !important;padding:75px 0px 75px 0px
}
}
.contact-form-container .form{max-width:700px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-container .form{max-width:90%;margin:0px auto !important;margin-left:0 !important
}
}
.contact-form-container .contact-input{width:100%;padding:5px 10px;margin-bottom:15px;background-color:#F7F7F7;border:2px solid #FFFFFF;color:#919191 !important;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;transition:all .2s ease;min-height:65px
}
.contact-form-container .contact-input:focus{color:#171519;border:2px solid #072635
}
.contact-form-container .contact-input-form{width:100%;padding:5px 10px;margin-bottom:15px;background-color:#FFFFFF;color:#919191 !important;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;line-height:38px;transition:all .2s ease;border:0;border-bottom:1px solid white
}
.contact-form-container .contact-input-form:focus{color:#171519;border-bottom:1px solid #072635
}
.contact-form-container :-ms-input-placeholder{color:#D3D3D3;opacity:1
}
.contact-form-container ::placeholder{color:#D3D3D3;opacity:1
}
.contact-form-container :-ms-input-placeholder{color:#D3D3D3
}
.contact-form-container ::-ms-input-placeholder{color:#D3D3D3
}
.contact-form-container .error-msg-container{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;min-height:50px
}
.contact-form-container .error-msg-container .error-msg{color:#072635;line-height:24px;font-size:16px;font-family:Roboto,Helvetica,Arial,sans-serif;margin-bottom:0
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-container .mirk-address{display:none !important
}
}
.contact-form-container .mirk-address p{line-height:32px;font-size:22px;font-family:Roboto,Helvetica,Arial,sans-serif;color:#5B5B5B;font-size:1.125rem;color:#171519
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-container .mirk-address p{font-size:1rem;line-height:1.4rem
}
}
.contact-form-container .btn-submit{position:relative;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:16px;line-height:48px;cursor:pointer
}
.contact-form-container .btn-submit .text{position:relative;color:#171519;transition:color 450ms linear 184ms;z-index:1
}
.contact-form-container .btn-submit .text:before{content:"";background:#3ce7af;opacity:.23;display:block;width:100%;height:13px;position:absolute;bottom:-5px;left:-0.15em;right:-0.15em;transition:300ms transform cubic-bezier(.165, .84, .44, 1);-ms-transform-origin:0% 100%;transform-origin:0% 100%;transform:scale(1, 0) translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden;background-repeat:repeat-x;z-index:-1
}
.contact-form-container .btn-submit:hover a{text-decoration:none
}
.contact-form-container .btn-submit:hover .text:before{transform:scale(1.15, .99999999) translateZ(0)
}
.contact-form-container .btn-submit:hover .angle-right{transition:.5s;-ms-transform:translateX(10px);transform:translateX(10px)
}
.custom-row{margin:0px !important
}
.input-field{padding:10px 0px
}
.contact-address-container{padding-left:5% !important;background-color:#072635;padding-top:130px;padding-bottom:120px;color:white
}
@media only screen and (min-width : 393px) and (max-width : 659px),only screen and (max-width : 392px){
.contact-address-container{padding-top:75px
}
}
.custom-button{box-sizing:border-box;padding:0px 20px;min-width:150px;border-radius:8px;border:1.5px solid #D9342A;color:#D9342A;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-line-pack:center;align-content:center;-ms-flex-align:center;align-items:center;-ms-flex-item-align:center;align-self:center;background-color:transparent
}
.custom-button:hover a{text-decoration:none
}
.custom-button:hover .text:before{transform:scale(1.15, .99999999) translateZ(0)
}
.custom-button:hover .angle-right{transition:.5s;-ms-transform:translateX(10px);transform:translateX(10px)
}
.booking-container{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:600px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.booking-container{width:unset
}
}
.booking-container .header{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center
}
.booking-container .title{font-size:42px;font-family:Roboto,Helvetica,Arial,sans-serif;color:#D9342A
}
.booking-container .input-container{background-color:#F3F3F3;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;padding:70px 30px
}
.booking-container .input-container .contact-input-form{width:100%;font-size:19px
}
.booking-close-btn-circle{width:45px;height:45px;border-radius:50%;background:white;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
.booking-close-btn-circle img{width:20px;height:20px
}
.modal-booking-container{height:98.5vh;background-color:black;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
#booking-demo{padding-right:0px !important;z-index:999 !important
}
.thank-you-message{color:#696969;font-size:18px;line-height:24px
}
#demoBooking{padding-right:0px !important;background:black;overflow:hidden
}
.send-container{padding:5vh 0px 2vh;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center
}
#contact .custom-button{height:58px;width:150px;color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;font-weight:300;border:1px solid #072635;background:#072635;border-radius:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-right:10px
}
#contact .custom-button p{margin-bottom:0
}
#contact .book-a-demo{height:58px;width:220px;color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px !important;font-weight:300;border:1px solid #72BF00;background:#72BF00;border-radius:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-right:10px
}
#contact .book-a-demo p{margin-bottom:0;font-size:19px !important
}
@media only screen and (max-width : 392px){
#contact .book-a-demo{width:230px
}
}