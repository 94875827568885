
*[data-v-94045d26]:focus{outline:none !important
}
.configurator[data-v-94045d26]{background:white
}
.config-content[data-v-94045d26]{width:90%;max-width:1600px;margin:0 auto;padding-left:5% !important;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.config-content[data-v-94045d26]{padding-bottom:0px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content[data-v-94045d26]{width:100%;padding-left:0 !important;padding-top:5vh;-ms-flex-direction:column;flex-direction:column
}
}
@media only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px),only screen and (min-width : 1024px) and (max-width : 1365px){
.config-content[data-v-94045d26]{width:95%;padding-left:0 !important
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.config-content[data-v-94045d26]{padding-top:5vh !important;padding-bottom:5vh !important
}
}
.config-content .questions[data-v-94045d26]{background-color:white;padding-top:10vh;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:80%
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .questions .your-event[data-v-94045d26]{max-width:95%;margin:0 auto
}
}
.config-content .questions .your-event-title[data-v-94045d26]{margin-bottom:30px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center
}
.config-content .questions .your-event-title h4[data-v-94045d26]{text-align:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .questions .your-event-title[data-v-94045d26]{margin-bottom:20px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .questions[data-v-94045d26]{width:100%;padding-bottom:5vh;border:none
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.config-content .questions[data-v-94045d26]{width:95%
}
}
.config-content .questions .contact-form[data-v-94045d26]{max-width:95%;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .questions .contact-form[data-v-94045d26]{max-width:90%
}
}
.config-content .questions .contact-form .col-2[data-v-94045d26]{padding-left:0
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .questions .contact-form .col-2[data-v-94045d26]{padding-left:15px
}
}
.config-content .need-help[data-v-94045d26]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding-top:10vh;padding-bottom:15vh;width:50%
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .need-help[data-v-94045d26]{width:100%;padding-top:5vh;padding-bottom:5vh
}
.config-content .need-help .questions-body[data-v-94045d26]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.config-content .need-help[data-v-94045d26]{width:40%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px){
.config-content .image-row[data-v-94045d26]{max-height:30vh
}
}
.config-content .row-item[data-v-94045d26]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;max-width:100%
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .row-item[data-v-94045d26]{padding-left:10px;padding-right:10px
}
}
.config-content .row-item img[data-v-94045d26]{width:100%;max-width:650px;margin:0 auto 5vh;object-fit:contain
}
.config-content .row-item li[data-v-94045d26]{margin-left:10px
}
.config-content .mobile-only[data-v-94045d26]{display:none
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .mobile-only[data-v-94045d26]{display:block;margin-bottom:20px
}
}
.config-content .options-row[data-v-94045d26]{-ms-flex-pack:justify;justify-content:space-between
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .options-row[data-v-94045d26]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column
}
}
.config-content .light-blue-btn[data-v-94045d26]{border:2px solid #9FCEE2;width:100%;border-radius:10px;height:60px;margin-bottom:10px;padding-right:15px;padding-left:15px;cursor:pointer;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center
}
.config-content .light-blue-btn P[data-v-94045d26]{color:#072635;font-size:20px;margin-left:10px;margin-right:10px;cursor:pointer
}
.config-content .custom-control-radio[data-v-94045d26]{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center
}
.config-content .custom-control-radio span[data-v-94045d26]{font-size:14px
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.config-content .custom-control-radio span[data-v-94045d26]{font-size:13px
}
}
.config-content .custom-control-radio .custom-label[data-v-94045d26]{display:-ms-flexbox !important;display:flex !important;-ms-flex-direction:row !important;flex-direction:row !important;width:100%;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;height:60px;margin-top:auto;margin-bottom:auto
}
.config-content .custom-control-radio .custom-label span[data-v-94045d26]{height:100%;padding-right:10px;display:-ms-flexbox !important;display:flex !important;-ms-flex-direction:row !important;flex-direction:row !important;-ms-flex-align:center;align-items:center
}
.config-content .custom-control-radio .custom-control-input[data-v-94045d26]{padding-top:10px
}
.config-content .form-group-custom[data-v-94045d26]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center
}
.config-content .button-wrapper[data-v-94045d26]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;cursor:pointer;width:100%
}
.config-content .button-wrapper .theme-image[data-v-94045d26]{max-width:100%;margin:2vh auto
}
.config-content .col-images[data-v-94045d26]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column
}
@media only screen and (max-width : 392px){
.config-content .col-images[data-v-94045d26]{max-width:95%
}
}
.config-content h1[data-v-94045d26]{color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-style:normal;font-weight:500;font-size:25px;line-height:29px;margin-left:30px;margin-bottom:75px;max-width:100%
}
.config-content h2[data-v-94045d26]{color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-style:normal;font-weight:500;font-size:25px;line-height:29px;margin-left:30px;margin-bottom:75px;max-width:100%
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content h2[data-v-94045d26]{margin-left:55px;margin-bottom:40px
}
}
.config-content h2[data-v-94045d26]{font-weight:bold;font-size:40px;line-height:47px;text-align:center;color:#072635;margin:0
}
.config-content h2 span[data-v-94045d26]{color:#72BF00
}
.config-content h3[data-v-94045d26]{font-weight:bold;font-size:25px;line-height:35px;text-align:center;color:#072635;margin:0
}
.config-content p[data-v-94045d26]{color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-style:normal;font-weight:500;font-size:25px;line-height:29px;margin-bottom:0;max-width:100%
}
.config-content .circle[data-v-94045d26]{min-width:26px;width:26px;height:26px;background-color:white;border:2px solid white;border-radius:13px;margin-right:20px
}
.config-content .circle.disabled[data-v-94045d26]{border:2px solid rgba(255,255,255,0.5);background-color:unset
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .circle[data-v-94045d26]{margin-right:0px;min-width:20px;width:20px;height:20px;background-color:white;border:2px solid white;border-radius:10px
}
}
.config-content .question-text[data-v-94045d26]{color:white;max-width:95%
}
.config-content .question-text.disabled[data-v-94045d26]{color:rgba(255,255,255,0.5)
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .question-text[data-v-94045d26]{display:none
}
}
.config-content .bar[data-v-94045d26]{background-color:rgba(255,255,255,0.5);width:2px;height:60px;margin-top:15px;margin-bottom:15px;margin-left:11px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .bar[data-v-94045d26]{width:15px;height:2px;margin-left:2px;margin-right:2px
}
}
.config-content .question-wrapper[data-v-94045d26]{max-width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding-right:150px
}
.config-content .question-wrapper ul[data-v-94045d26]{margin-bottom:5px!important;font-weight:bold;font-size:20px;line-height:23px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .question-wrapper[data-v-94045d26]{padding-right:0px;-ms-flex-direction:row;flex-direction:row
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.config-content .question-wrapper[data-v-94045d26]{padding-right:80px
}
}
.config-content .question-wrapper:last-child .bar[data-v-94045d26]{display:none
}
.config-content .icon[data-v-94045d26]{width:25px;height:25px
}
.config-content .btn-next[data-v-94045d26]{height:45px;width:130px;color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;font-weight:300;border:1px solid #72BF00;background:#72BF00;border-radius:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-top:5vh;margin-left:auto;margin-right:auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .btn-next[data-v-94045d26]{margin-top:5vh
}
}
.config-content .error[data-v-94045d26]{margin-top:0;margin-right:10px;color:red;font-size:12px;text-align:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.config-content .error[data-v-94045d26]{margin-right:10vw
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .error[data-v-94045d26]{margin-right:10vw
}
}
.config-content .small-margin[data-v-94045d26]{margin-bottom:50px !important
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .form-group[data-v-94045d26]{margin-bottom:0
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .col-sm-12[data-v-94045d26]{padding-left:0
}
}
.config-content .contact-form .options-row h5[data-v-94045d26]{font-style:normal;font-weight:normal;font-size:20px;line-height:23px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .contact-form .options-row[data-v-94045d26]{margin:0 auto
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.config-content .col-lg-5[data-v-94045d26]{padding:0
}
}
.config-content .col-lg-6[data-v-94045d26]{padding-left:30px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .col-lg-6[data-v-94045d26]{padding:0
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.config-content .col-lg-6[data-v-94045d26]{padding:0
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 768px) and (max-width : 1023px){
.config-content .col-sm-12[data-v-94045d26]{padding:0 !important
}
}
.config-content .col-1[data-v-94045d26]{max-width:50px;margin-left:0
}