
*[data-v-0760fb5c]:focus{outline:none !important
}
.privacy-policy[data-v-0760fb5c]{width:90%;max-width:1600px;margin:0 auto;padding-left:3%;padding-top:100px;padding-bottom:100px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.privacy-policy[data-v-0760fb5c]{padding-left:0 !important;width:90%
}
}
.privacy-policy .section-title[data-v-0760fb5c]{font-weight:700 !important;font-size:55px;line-height:65px;margin-top:15px;margin-bottom:35px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.privacy-policy .section-title[data-v-0760fb5c]{font-size:32px;line-height:47px
}
}
.privacy-policy .section-title-md[data-v-0760fb5c]{font-weight:700 !important;font-size:35px;line-height:45px;margin-top:25px;margin-bottom:25px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.privacy-policy .section-title-md[data-v-0760fb5c]{font-size:32px;line-height:47px
}
}
.privacy-policy p[data-v-0760fb5c],.privacy-policy ol[data-v-0760fb5c]{font-size:20px;line-height:30px;color:#212529
}
.privacy-policy p[data-v-0760fb5c]{margin-top:10px
}
.privacy-policy .bold[data-v-0760fb5c]{font-weight:bold;margin-top:50px
}
.privacy-policy .underline[data-v-0760fb5c]{text-decoration:underline;margin-top:20px
}
.privacy-policy ol[data-v-0760fb5c]{list-style-type:inherit
}
.privacy-policy .number[data-v-0760fb5c]{list-style-type:decimal
}
.privacy-policy .o[data-v-0760fb5c]{list-style-type:upper-alpha
}