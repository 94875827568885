
#appy{
  width: 100%;
  height: 100vh;
  min-height: 100%;
  max-height: 100%;
}
iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/*body{*/
/*  overflow: hidden;*/
/*}*/
