
*:focus{outline:none !important
}
.contact-form-only{padding-right:0 !important
}
.contact-form-only .custom-col{padding-left:0;margin-bottom:40px
}
@media only screen and (max-width : 392px){
.contact-form-only .custom-col{margin-bottom:10px
}
}
@media only screen and (max-width : 392px){
.contact-form-only .footer-container-contact{padding:15px !important
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-only .footer-container-contact .col-6{width:100% !important;max-width:100% !important
}
}
@media only screen and (max-width : 392px){
.contact-form-only .footer-container-contact .mobile{padding-bottom:10px;-ms-flex:0 0 100% !important;flex:0 0 100% !important
}
}
.contact-form-only .footer-container-contact .stay-in-loop{padding-left:0
}
@media only screen and (max-width : 392px){
.contact-form-only .footer-container-contact .stay-in-loop{padding-top:35px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-only .footer-container-contact .stay-in-loop{padding-top:20px
}
}
.contact-form-only .footer-container-contact .footer-bottom{margin-top:50px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .footer-container-contact .footer-bottom .col-8{max-width:100%;-ms-flex:0 0 100% !important;flex:0 0 100% !important;padding-bottom:35px
}
}
.contact-form-only .opaque{opacity:0;-ms-transform:translateY(200px);transform:translateY(200px)
}
.contact-form-only a:hover{text-decoration:none
}
.contact-form-only .footer-container-new{width:92%;background-color:white;box-sizing:border-box;padding:30px 0 30px 0;margin:0 auto
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.contact-form-only .footer-container-new{width:90%
}
}
.contact-form-only #addresses{margin:0 auto
}
.contact-form-only .address-header{font-size:14px;font-family:Roboto,Helvetica,Arial,sans-serif;line-height:23px
}
.contact-form-only .address{font-size:20px;line-height:23px;font-family:Roboto,Helvetica,Arial,sans-serif;color:#5B5B5B
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .address{font-size:14px;line-height:21px
}
}
.contact-form-only .footer-info-description{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#171519;margin-bottom:5px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .footer-info-description{font-size:12px
}
}
.contact-form-only .footer-info-description-text{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#171519
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .footer-info-description-text{font-size:12px
}
}
.contact-form-only .footer-info-header{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:23px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .footer-info-header{font-size:14px
}
}
.contact-form-only #social-media{display:-ms-flexbox;display:flex
}
.contact-form-only #social-media .item{font-family:Roboto,Helvetica,Arial,sans-serif;font-size:13px;margin-right:15px;color:#5B5B5B
}
.contact-form-only #social-media .item .link{color:#5B5B5B
}
.contact-form-only .copyright{color:#5B5B5B;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:13px;display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .copyright{padding-left:15px !important;-ms-flex-pack:center;justify-content:center
}
}
.contact-form-only .upperCase{text-transform:uppercase
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-only .col-5{width:100% !important;max-width:100% !important
}
}
.contact-form-only .mirk-home-link p{font-size:1.2vw;font-weight:bold;color:black;text-decoration:underline
}
.contact-form-only .contact-form-container{padding-left:5%;background-color:#F3F3F3
}
.contact-form-only .contact-form-container .wrapper{width:90%;max-width:1600px;margin:0px auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-only .contact-form-container .wrapper{width:100%;margin:0px auto !important
}
}
.contact-form-only .contact-form-container .section{margin:100px auto;max-width:700px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-only .contact-form-container .section{margin:70px auto !important;padding:75px 0px 75px 0px
}
}
.contact-form-only .contact-form-container .form{max-width:700px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-only .contact-form-container .form{max-width:90%;margin:0px auto !important
}
}
.contact-form-only .contact-form-container .contact-input{width:100%;padding:5px 10px;margin-bottom:15px;background-color:#F7F7F7;border:2px solid #FFFFFF;color:#919191 !important;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;transition:all .2s ease;min-height:65px
}
.contact-form-only .contact-form-container .contact-input:focus{color:#171519;border:2px solid #72BF00
}
.contact-form-only .contact-form-container .contact-input-form{width:100%;padding:5px 10px;margin-bottom:15px;background-color:#FFFFFF;color:#919191 !important;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;line-height:38px;transition:all .2s ease;border:0
}
.contact-form-only .contact-form-container .contact-input-form:focus{color:#171519;border-bottom:1px solid #72BF00
}
.contact-form-only .contact-form-container :-ms-input-placeholder{color:#D3D3D3;opacity:1
}
.contact-form-only .contact-form-container ::placeholder{color:#D3D3D3;opacity:1
}
.contact-form-only .contact-form-container :-ms-input-placeholder{color:#D3D3D3
}
.contact-form-only .contact-form-container ::-ms-input-placeholder{color:#D3D3D3
}
.contact-form-only .contact-form-container .error-msg-container{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;min-height:50px
}
.contact-form-only .contact-form-container .error-msg-container .error-msg{color:#72BF00;line-height:24px;font-size:20px;font-family:Roboto,Helvetica,Arial,sans-serif;margin-bottom:0
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .contact-form-container .mirk-address{display:none !important
}
}
.contact-form-only .contact-form-container .mirk-address p{line-height:32px;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:1.125rem;color:#171519
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.contact-form-only .contact-form-container .mirk-address p{font-size:1rem;line-height:1.4rem
}
}
.contact-form-only .contact-form-container .btn-submit{position:relative;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:48px;cursor:pointer
}
.contact-form-only .contact-form-container .btn-submit .text{position:relative;color:#171519;transition:color 450ms linear 184ms;z-index:1
}
.contact-form-only .contact-form-container .btn-submit .text:before{content:"";background:#3ce7af;opacity:.23;display:block;width:100%;height:13px;position:absolute;bottom:-5px;left:-0.15em;right:-0.15em;transition:300ms transform cubic-bezier(.165, .84, .44, 1);-ms-transform-origin:0% 100%;transform-origin:0% 100%;transform:scale(1, 0) translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden;background-repeat:repeat-x;z-index:-1
}
.contact-form-only .contact-form-container .btn-submit:hover a{text-decoration:none
}
.contact-form-only .contact-form-container .btn-submit:hover .text:before{transform:scale(1.15, .99999999) translateZ(0)
}
.contact-form-only .contact-form-container .btn-submit:hover .angle-right{transition:.5s;-ms-transform:translateX(10px);transform:translateX(10px)
}
.contact-form-only .custom-row{margin:0px !important
}
.contact-form-only .input-field{padding:10px 0px
}
.contact-form-only .input-field h1{font-weight:bold
}
.contact-form-only .configurator-form-msg p{font-size:20px;line-height:23px
}
.contact-form-only .contact-address-container{padding-left:5%;background-color:#000000;padding-top:120px;padding-bottom:50px;color:white
}
@media only screen and (min-width : 393px) and (max-width : 659px),only screen and (max-width : 392px){
.contact-form-only .contact-address-container{padding-top:75px
}
}
.contact-form-only .social-link-container{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding-top:200px;width:90%;-ms-flex-wrap:wrap;flex-wrap:wrap
}
.contact-form-only .social-link-container a{color:#BABABA;font-size:20px;line-height:30px;font-family:Roboto,Helvetica,Arial,sans-serif
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .social-link-container a{font-size:15px
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.contact-form-only .social-link-container{padding-top:230px
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.contact-form-only .social-link-container div{width:100%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .social-link-container{padding-top:0px;width:100%;-ms-flex-pack:distribute;justify-content:space-around
}
}
.contact-form-only .custom-button{box-sizing:border-box;padding:0px 20px;min-width:150px;border-radius:8px;border:1.5px solid #72BF00;color:#72BF00;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-line-pack:center;align-content:center;-ms-flex-align:center;align-items:center;-ms-flex-item-align:center;align-self:center;background-color:transparent
}
.contact-form-only .custom-button .text{margin-bottom:0
}
.contact-form-only .custom-button:hover a{text-decoration:none
}
.contact-form-only .custom-button:hover .text:before{transform:scale(1.15, .99999999) translateZ(0)
}
.contact-form-only .custom-button:hover .angle-right{transition:.5s;-ms-transform:translateX(10px);transform:translateX(10px)
}
.contact-form-only .booking-container{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:600px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .booking-container{width:unset
}
}
.contact-form-only .booking-container .header{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center
}
.contact-form-only .booking-container .title{font-size:42px;font-family:Roboto,Helvetica,Arial,sans-serif;color:#D9342A
}
.contact-form-only .booking-container .input-container{background-color:#F3F3F3;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;padding:70px 30px
}
.contact-form-only .booking-container .input-container .contact-input-form{width:100%;font-size:20px
}
.contact-form-only .booking-close-btn-circle{width:45px;height:45px;border-radius:50%;background:white;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
.contact-form-only .booking-close-btn-circle img{width:20px;height:20px
}
.contact-form-only .modal-booking-container{height:98.5vh;background-color:black;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
.contact-form-only #booking-demo{padding-right:0px !important;z-index:999 !important
}
.contact-form-only .thank-you-message{color:#696969;font-size:18px;line-height:24px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .thank-you-message{margin-top:10px
}
}
.contact-form-only #demoBooking{padding-right:0px !important;background:black;overflow:hidden !important;max-height:100vh;z-index:99999 !important
}
.contact-form-only .send-container{padding:5vh 0px 2vh;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center
}
.contact-form-only #contact{margin-top:60px !important
}
.contact-form-only .menu-logo{padding:50px 0px 20px
}
.contact-form-only .little-m{transition:.3s;width:200px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .little-m{width:220px
}
}
.contact-form-only .contact-address-container--menu{background:white
}
.contact-form-only .modal-wrapper{height:100vh
}
.contact-form-only .close-btn--menu{margin:50px 50px 0px 0px
}
.contact-form-only .cross{cursor:pointer;width:25px;float:right;margin-right:10px;margin-top:15px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.contact-form-only .cross{width:15px;margin-top:18px
}
}
.contact-form-only .contact-address-container--menu{padding-left:10vw
}
.contact-form-only .custom-button{box-sizing:border-box;padding:0px 20px;min-width:150px;border-radius:8px;border:1.5px solid #072635;color:#072635;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-line-pack:center;align-content:center;-ms-flex-align:center;align-items:center;-ms-flex-item-align:center;align-self:center;background-color:transparent
}
.contact-form-only .custom-button .text{margin-bottom:0;padding-top:10px;padding-bottom:10px;font-size:18px !important;line-height:24px !important
}
.contact-form-only .custom-button:hover a{text-decoration:none
}
.contact-form-only .custom-button:hover .text:before{transform:scale(1.15, .99999999) translateZ(0)
}
.contact-form-only .custom-button:hover .angle-right{transition:.5s;-ms-transform:translateX(10px);transform:translateX(10px)
}
.configurator-form .input-field{margin:0 auto
}
.configurator-form .contact-input-form{width:100%;padding:5px 10px;margin-bottom:15px;background-color:#FFFFFF;color:#072635 !important;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;line-height:38px;transition:all .2s ease;border:0;border-bottom:1px solid #072635
}
.configurator-form .contact-input-form:focus{color:#171519;border-bottom:1px solid #72BF00
}