
*:focus{outline:none !important
}
nav{position:fixed;display:block;top:-100px;left:0px;background-color:#072635;height:85px;width:100%;max-width:100vw;z-index:999;transition:all .35s;border-radius:1px;box-shadow:0 3px 20px 0 rgba(0,0,0,0.1);-o-box-shadow:0 3px 20px 0 rgba(0,0,0,0.1)
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
nav{height:75px
}
}
nav .logo-burger{width:95%;margin:0 auto;padding-top:13px;padding-bottom:13px;max-width:1600px;background-color:#072635
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
nav .logo-burger{max-width:100%;width:93%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
nav .logo-burger .col-sm-4,nav .logo-burger .col-sm-3{max-width:50%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
nav .logo-burger .col-sm-8,nav .logo-burger .col-sm-9{max-width:50%
}
}
nav .logo-burger .hamburger{z-index:12;cursor:pointer;display:inline-block;float:right;margin-top:-3px
}
@media only screen and (min-width: 1800px){
nav .logo-burger .hamburger img{width:28px
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
nav .logo-burger .hamburger img{width:28px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
nav .logo-burger .hamburger img{width:25px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 393px) and (max-width : 659px){
nav .logo-burger .hamburger img{width:19px
}
}
nav .logo-burger g{fill:white
}
nav .logo-burger .mlogo{width:auto;cursor:pointer;display:inline-block
}
@media only screen and (min-width: 1800px){
nav .logo-burger .mlogo{height:45px
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
nav .logo-burger .mlogo{height:48px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
nav .logo-burger .mlogo{height:41px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 393px) and (max-width : 659px){
nav .logo-burger .mlogo{height:39px
}
}
.nav-button span{color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;font-weight:300
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px),only screen and (min-width : 660px) and (max-width : 1023px){
.nav-button span{font-size:18px
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.nav-button span{font-size:15px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.nav-button{display:none
}
}
@media only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.nav-button{display:block
}
}
.nav-button-get-started{height:58px;width:190px;color:#16A7C9;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;font-weight:300;border:1px solid #16A7C9;border-radius:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.nav-button-get-started{width:175px;margin-right:10px
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px){
.nav-button-get-started{height:50px
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.nav-button-get-started{font-size:16px;height:50px;width:170px
}
}
.nav-button-book-demo{height:58px;width:170px;color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;font-weight:300;border:1px solid #72BF00;background:#72BF00;border-radius:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-right:15px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.nav-button-book-demo{display:none
}
}
.nav-button-book-demo a{color:white
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.nav-button-book-demo{height:50px;width:150px
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.nav-button-book-demo{font-size:16px;height:50px;width:150px
}
}
#navbar-mobile{display:none
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
#navbar-mobile{display:block
}
}
#navbar-desktop{display:block
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
#navbar-desktop{display:none
}
}