
*:focus{outline:none !important
}
.book-a-demo .feat-fade-left{opacity:0;-ms-transform:translateX(-100px);transform:translateX(-100px)
}
.book-a-demo .feat-fade-left-two-two{opacity:0;-ms-transform:translateX(-100px);transform:translateX(-100px)
}
.book-a-demo .feat-fade-up-left{opacity:0;-ms-transform:translateY(100px);transform:translateY(100px)
}
.book-a-demo .feat-fade-up-right{opacity:0;-ms-transform:translateY(100px);transform:translateY(100px)
}
.book-a-demo .pointer{cursor:pointer
}
.book-a-demo .featured-container{width:100%;position:relative;box-sizing:border-box;overflow:hidden;z-index:7
}
@media only screen and (min-width : 1024px) and (max-width : 1365px),only screen and (min-width : 768px) and (max-width : 1023px){
.book-a-demo .featured-container{height:580px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .featured-container{height:630px
}
}
.book-a-demo .featured-container .featured-content{background:#072635;height:120%;min-height:760px
}
@media only screen and (min-width : 1024px) and (max-width : 1365px),only screen and (min-width : 768px) and (max-width : 1023px){
.book-a-demo .featured-container .featured-content{height:100%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .featured-container .featured-content{height:100%
}
}
.book-a-demo .featured-container .featured-content .featured-container{height:760px;width:90%;max-width:1600px;margin:0 auto;padding-left:5% !important
}
@media only screen and (min-width : 1024px) and (max-width : 1365px),only screen and (min-width : 768px) and (max-width : 1023px){
.book-a-demo .featured-container .featured-content .featured-container{height:580px;width:93vw !important
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .featured-container .featured-content .featured-container{height:630px;width:90%;padding-left:0 !important
}
}
.book-a-demo .featured-container .featured-content .booking-info{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;height:100%;padding-left:0 !important;padding-right:0 !important
}
.book-a-demo .featured-container .featured-content .project-description{font-family:Roboto,Helvetica,Arial,sans-serif;color:#FFFFFF;font-size:20px;line-height:30px;padding:10px 0px;max-width:500px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .featured-container .featured-content .project-description{font-size:20px;font-family:Roboto,Helvetica,Arial,sans-serif;line-height:30px;padding:0px
}
}
.book-a-demo .featured-container .featured-content .project-name{padding:0;margin:0 0 30px;color:#FFFFFF;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:55px;line-height:65px;font-weight:700
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .featured-container .featured-content .project-name{font-size:32px;line-height:47px;padding-bottom:25px
}
}
.book-a-demo .featured-container .featured-content .hr-white{width:70px;margin:0px 0px 20px 0px;height:7px;background-color:#FFFFFF;border:0
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .featured-container .featured-content .hr-white{height:3px;width:41px;margin-bottom:10px
}
}
.book-a-demo .featured-container .featured-content .section-head{text-align:left;color:#8BCC55;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:15px;font-weight:400;line-height:20px;text-transform:uppercase
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .featured-container .featured-content .section-head{font-size:12px;line-height:20px;margin-bottom:10px
}
}
@media only screen and (min-width: 1800px){
.book-a-demo .featured-container .featured-content .section-head{line-height:48px
}
}
.book-a-demo .featured-container .featured-content .proj-button-container{margin:0px;padding:0
}
.book-a-demo .featured-container .featured-content .proj-button-container .view-all-button{width:150px;color:#FFFFFF;font-family:Roboto,Helvetica,Arial,sans-serif;background-color:#000000;text-align:center;font-size:13px;font-weight:900;line-height:20px;padding:10px;transition:all .5s;-webkit-transition:all .5s;-moz-transition:all .5s;-o-transition:all .5s;cursor:pointer;margin-top:20px;position:relative;text-decoration:none;display:inline-block;border-radius:8px;border:1px solid #072635
}
.book-a-demo .featured-container .featured-content .proj-button-container .view-all-button:hover svg{fill:#FFFFFF;-ms-transform:translateX(5px);transform:translateX(5px)
}
.book-a-demo .featured-container .featured-content .proj-button-container .view-all-button g{transition:all .5s;-webkit-transition:all .5s;-moz-transition:all .5s;-o-transition:all .5s
}
.book-a-demo .featured-container .featured-content .proj-button-container .view-all-button:hover g{fill:#FFFFFF
}
.book-a-demo .featured-container .featured-content .proj-button-container .view-all-button:hover{color:#171519
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .featured-container .featured-content .proj-button-container .view-all-button{font-size:12px;width:125px;padding:5px;margin:0
}
}
.book-a-demo .featured-container .featured-content .proj-button-container .view-all-button span>svg{position:absolute;display:block;right:10%;top:35%;transition:all .5s;-webkit-transition:all .5s;-moz-transition:all .5s;-o-transition:all .5s
}
.book-a-demo .featured-container .featured-content .proj-button-container .view-all-button span>svg g{fill:#1D2230
}
.book-a-demo .modal-lg{max-width:100vw;height:100vh;z-index:9999999 !important
}
.book-a-demo .modal-close-btn-container{display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end;margin-top:-20px;background-color:black;padding-top:5px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .modal-close-btn-container{padding-top:30px
}
}
.book-a-demo .modal-close-btn-container .close{padding:10px
}
.book-a-demo .modal-video-container{height:92vh;background-color:black;z-index:1000 !important;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
.book-a-demo .modal-backdrop{opacity:1 !important;position:fixed;top:0px;left:0px;z-index:0 !important;width:100vw !important;background-color:#000
}
.book-a-demo .custom-button{height:58px;width:150px;color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;font-weight:300;border:1px solid #072635;background:#072635;border-radius:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-right:10px
}
.book-a-demo .custom-button span{margin-bottom:0;font-size:18px !important;line-height:24px !important
}
.book-a-demo .custom-button:hover a{text-decoration:none
}
.book-a-demo .custom-button:hover .text:before{transform:scale(1.15, .99999999) translateZ(0)
}
.book-a-demo .custom-button:hover .angle-right{transition:.5s;-ms-transform:translateX(10px);transform:translateX(10px)
}
.book-a-demo .custom-button-spaces{box-sizing:border-box;padding:0px 20px;min-width:150px;margin-top:10px;border-radius:8px;background:white !important;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-line-pack:center;align-content:center;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center !important;align-items:center !important;max-width:190px;height:70px
}
.book-a-demo .custom-button-spaces span{color:#072635 !important;margin-bottom:0 !important;font-size:20px;line-height:30px;font-weight:400;font-family:Roboto,Helvetica,Arial,sans-serif
}
.book-a-demo .custom-button-spaces:hover a{text-decoration:none
}
.book-a-demo .custom-button-spaces:hover .text:before{transform:scale(1.15, .99999999) translateZ(0)
}
.book-a-demo .custom-button-spaces:hover .angle-right{transition:.5s;-ms-transform:translateX(10px);transform:translateX(10px)
}
.book-a-demo .contact-form-container{padding-left:5%;background-color:#F3F3F3
}
.book-a-demo .contact-form-container .wrapper{width:90%;max-width:1600px;margin:0px auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .contact-form-container .wrapper{width:100%;margin:0px auto !important
}
}
.book-a-demo .contact-form-container .section{margin:100px auto;padding:150px 0px 50px 0px;max-width:700px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .contact-form-container .section{margin:70px auto !important;padding:75px 0px 75px 0px
}
}
.book-a-demo .contact-form-container .contact-input{width:100%;padding:5px 10px;margin-bottom:15px;background-color:#F7F7F7;border:2px solid #FFFFFF;color:#919191 !important;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;transition:all .2s ease;min-height:65px
}
.book-a-demo .contact-form-container .contact-input:focus{color:#171519;border:2px solid #072635
}
.book-a-demo .contact-form-container .contact-input-form{width:100%;padding:5px 10px;margin-bottom:15px;background-color:#FFFFFF;color:#072635 !important;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;line-height:38px;transition:all .2s ease;border:0;border-bottom:1px solid white
}
.book-a-demo .contact-form-container .contact-input-form:focus{color:#171519;border-bottom:1px solid #072635
}
.book-a-demo .contact-form-container :-ms-input-placeholder{color:#072635;opacity:1
}
.book-a-demo .contact-form-container ::placeholder{color:#072635;opacity:1
}
.book-a-demo .contact-form-container :-ms-input-placeholder{color:#072635
}
.book-a-demo .contact-form-container ::-ms-input-placeholder{color:#072635
}
.book-a-demo .contact-form-container .error-msg-container{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;min-height:50px
}
.book-a-demo .contact-form-container .error-msg-container .error-msg{color:#072635;line-height:24px;font-size:20px;font-family:Roboto,Helvetica,Arial,sans-serif;margin-bottom:0
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .contact-form-container .error-msg-container{padding-left:15px;margin-top:10px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .contact-form-container .mirk-address{display:none !important
}
}
.book-a-demo .contact-form-container .mirk-address p{line-height:32px;font-size:22px;font-family:Roboto,Helvetica,Arial,sans-serif;color:#5B5B5B
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .contact-form-container .mirk-address p{font-size:1rem;line-height:1.4rem
}
}
.book-a-demo .booking-container{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:600px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .booking-container{width:90vw
}
}
.book-a-demo .booking-container .header{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;margin:0;padding:5px 0
}
.book-a-demo .booking-container .title{font-size:42px;font-family:Roboto,Helvetica,Arial,sans-serif;color:#72BF00;font-weight:300
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .booking-container .title{font-size:32px
}
}
.book-a-demo .booking-container .input-container{background-color:#F3F3F3;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;padding:70px 30px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .booking-container .input-container{padding:70px 10px 30px
}
}
.book-a-demo .booking-container .input-container .contact-input-form{width:100%;font-size:20px
}
.book-a-demo .booking-container .form{max-width:700px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo .booking-container .form{max-width:90vw;min-width:90vw;margin:0px auto !important
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .col-md-12{padding-left:0;padding-right:0
}
}
.book-a-demo .booking-close-btn-circle{width:45px;height:45px;border-radius:50%;background:white;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
.book-a-demo .booking-close-btn-circle img{width:20px;height:20px
}
.book-a-demo .modal-booking-container{height:100vh;background-color:black;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
.book-a-demo #booking-demo{padding-right:0px !important
}
.book-a-demo .thank-you-message{color:#696969;font-size:18px;line-height:24px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.book-a-demo .thank-you-message{margin-top:10px
}
}
.book-a-demo #demoBooking{padding-right:0px !important;background:black;overflow:hidden !important;max-height:100vh;z-index:99999 !important
}
.book-a-demo #demoBooking .section{max-width:700px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.book-a-demo #demoBooking .section{max-width:90vw;min-width:80vw;margin:0 auto !important
}
}
.book-a-demo .send-container{padding:5vh 0px 2vh;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center
}
.modal-backdrop.show{display:none
}
.modal-body{border:none !important
}