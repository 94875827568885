
*[data-v-a8f0eab8]:focus{outline:none !important
}
.animate-up[data-v-a8f0eab8]{animation:1.5s down-leave
}
@keyframes down-leave{
100%{-webkit-clip-path:inset(0);clip-path:inset(0)
}
0%{-webkit-clip-path:inset(100% 0 0 0);clip-path:inset(100% 0 0 0)
}
}
.overlay[data-v-a8f0eab8]{background:#FFFFFF !important;opacity:0
}
.left-side[data-v-a8f0eab8]{margin-right:10% !important
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.left-side[data-v-a8f0eab8]{margin-left:0
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.left-side .service-description[data-v-a8f0eab8]{margin-left:5%
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.left-side[data-v-a8f0eab8]{padding-right:3%
}
}
.home-item-wrapper[data-v-a8f0eab8]{margin:0 auto;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;padding-top:1vh;padding-bottom:0
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.home-item-wrapper[data-v-a8f0eab8]{padding-left:0% !important;padding-bottom:5vh
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.home-item-wrapper[data-v-a8f0eab8]{padding-bottom:5vh;padding-left:4% !important
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.home-item-wrapper[data-v-a8f0eab8]{margin:0px auto 0px auto;-ms-flex-direction:column;flex-direction:column;padding-top:50px
}
}
.home-item-wrapper .left-img[data-v-a8f0eab8]{margin:0px auto 50px auto
}
.home-item-wrapper .image-item[data-v-a8f0eab8]{max-width:100%;max-height:561px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.home-item-wrapper .image-item[data-v-a8f0eab8]{margin-top:80px
}
}
.home-item-wrapper .image-item img[data-v-a8f0eab8]{width:100%;height:100%;object-fit:contain
}
.home-item-wrapper .demo-button[data-v-a8f0eab8]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;height:58px;border-radius:8px;background-color:#72BF00;width:170px;cursor:pointer;text-align:center
}
.home-item-wrapper .demo-button .text[data-v-a8f0eab8]{color:#FFFFFF;text-align:center
}
.home-item-wrapper .demo-button .text[data-v-a8f0eab8]:hover{cursor:pointer
}
.home-item-wrapper .demo-button a[data-v-a8f0eab8]{width:100%;height:100%;vertical-align:middle;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;color:#FFFFFF
}
.home-item-wrapper .home-item-left[data-v-a8f0eab8]{max-width:45%;padding-left:10%;padding-right:0
}
@media only screen and (min-width: 1800px){
.home-item-wrapper .home-item-left[data-v-a8f0eab8]{padding-left:20%
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.home-item-wrapper .home-item-left[data-v-a8f0eab8]{padding-left:4%
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.home-item-wrapper .home-item-left[data-v-a8f0eab8]{padding-left:0%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.home-item-wrapper .home-item-left[data-v-a8f0eab8]{padding-left:0%;max-width:90vw
}
}
.home-item-wrapper .home-item-left .title[data-v-a8f0eab8]{font-size:45px;line-height:55px;font-family:Roboto,Helvetica,Arial,sans-serif;text-align:left;color:white;font-weight:900
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.home-item-wrapper .home-item-left .title[data-v-a8f0eab8]{font-size:37px;line-height:47px
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.home-item-wrapper .home-item-left .title[data-v-a8f0eab8]{font-size:36px;line-height:46px
}
}
@media only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.home-item-wrapper .home-item-left .title[data-v-a8f0eab8]{font-size:40px;line-height:50px
}
}
.home-item-wrapper .home-item-left .description[data-v-a8f0eab8]{font-size:20px;line-height:30px;font-family:Roboto,Helvetica,Arial,sans-serif;text-align:left;padding-top:30px;padding-bottom:10px;color:white;font-weight:500;max-width:450px
}
@media only screen and (min-width : 393px) and (max-width : 659px),only screen and (max-width : 392px){
.home-item-wrapper .home-item-left .description[data-v-a8f0eab8]{max-width:90%
}
}
.home-item-wrapper .home-item-left .btn-featured-project[data-v-a8f0eab8]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;height:58px;width:208px;border-radius:20px;cursor:pointer;text-align:center
}
.home-item-wrapper .home-item-left .btn-featured-project .text[data-v-a8f0eab8]{color:#FFFFFF;text-align:center
}
.home-item-wrapper .home-item-left .btn-featured-project .text[data-v-a8f0eab8]:hover{cursor:pointer
}
.home-item-wrapper .home-item-left .btn-featured-project a[data-v-a8f0eab8]{width:100%;height:100%;vertical-align:middle;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
.home-item-wrapper .home-item-right[data-v-a8f0eab8]{width:50%;max-height:100%
}
.home-item-wrapper .home-item-right img[data-v-a8f0eab8]{object-fit:contain;max-height:100%;max-width:100%
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.home-item-wrapper .home-item-right[data-v-a8f0eab8]{width:100%
}
}
.service-description[data-v-a8f0eab8]{max-width:50%;padding-left:5%
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.service-description[data-v-a8f0eab8]{padding-left:5%
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.service-description[data-v-a8f0eab8]{padding-left:0;max-width:90vw
}
}
.service-description .title[data-v-a8f0eab8]{font-size:42px;line-height:54px;font-family:Roboto,Helvetica,Arial,sans-serif;text-align:left
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.service-description .title[data-v-a8f0eab8]{font-size:32px;line-height:34px
}
}
.service-description .description[data-v-a8f0eab8]{font-size:20px;line-height:28px;font-family:Roboto,Helvetica,Arial,sans-serif;text-align:left;padding-top:30px;padding-bottom:30px
}
.service-description .btn-featured-project[data-v-a8f0eab8]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;height:58px;border-radius:20px;cursor:pointer;text-align:center
}
.service-description .btn-featured-project .text[data-v-a8f0eab8]{color:#FFFFFF;text-align:center
}
.service-description .btn-featured-project .text[data-v-a8f0eab8]:hover{cursor:pointer
}
.service-description .btn-featured-project a[data-v-a8f0eab8]{width:100%;height:100%;vertical-align:middle;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;color:#FFFFFF
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.service-description #learning_experiences_desc[data-v-a8f0eab8]{-ms-flex-order:2;order:2
}
}
.service-description .project-image[data-v-a8f0eab8]{width:100%;max-height:655px
}
.service-description .project-image img[data-v-a8f0eab8]{height:100%;max-width:100%;object-fit:cover
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.service-description .project-image[data-v-a8f0eab8]{width:100%;height:230px
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.service-description .project-image[data-v-a8f0eab8]{max-width:300px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.service-description .project-image-mobile img[data-v-a8f0eab8]{width:90vw
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.service-description .project-image-mobile[data-v-a8f0eab8]{margin-right:0 !important
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px),only screen and (min-width: 1800px),only screen and (min-width : 660px) and (max-width : 1023px){
.service-description .project-image-mobile[data-v-a8f0eab8]{display:none
}
}
.logos-container[data-v-a8f0eab8]{-ms-flex-direction:row !important;flex-direction:row !important;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;height:58px;width:100%;border-radius:20px;cursor:pointer;text-align:center
}
.logos-container .text[data-v-a8f0eab8]{color:#FFFFFF;text-align:center
}
.logos-container .text[data-v-a8f0eab8]:hover{cursor:pointer
}
.logos-container a[data-v-a8f0eab8]{width:100%;height:100%;vertical-align:middle;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;color:#FFFFFF
}
.logos-container .logo-images .mirk-image[data-v-a8f0eab8]{width:190px
}
@media only screen and (max-width : 392px){
.logos-container .logo-images .mirk-image[data-v-a8f0eab8]{width:100%
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.logos-container .logo-images .mirk-image[data-v-a8f0eab8]{width:100px
}
}
.logos-container .logo-images .image[data-v-a8f0eab8]{width:250px
}
@media only screen and (max-width : 392px){
.logos-container .logo-images .image[data-v-a8f0eab8]{width:100%
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.logos-container .logo-images .image[data-v-a8f0eab8]{width:100px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
}
.video-controller-container[data-v-a8f0eab8]{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;padding:30px 10px
}
.video-controller-container .play-video-text[data-v-a8f0eab8]{color:#6bbb43;padding:0px 20px;font-weight:bold;font-size:.9rem
}
#videoModalSpaces[data-v-a8f0eab8]{padding-right:0!important
}
#videoModalSpaces .modal-dialog[data-v-a8f0eab8]{max-width:98% !important;min-width:98% !important;height:auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
#videoModalSpaces .modal-dialog[data-v-a8f0eab8]{max-width:100% !important;min-width:100% !important;margin:0!important
}
}
#videoModalSpaces .modal-body[data-v-a8f0eab8]{padding:0!important
}
#videoModalSpaces .modal-content[data-v-a8f0eab8]{pointer-events:unset;background-color:unset;background-clip:padding-box;border:unset !important;outline:0;min-height:90vh !important
}
.seo-header[data-v-a8f0eab8]{display:none
}
.mirk-description[data-v-a8f0eab8]{width:40%;margin:40px auto 40px auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.mirk-description[data-v-a8f0eab8]{padding-bottom:60px;width:70%
}
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
.mirk-description[data-v-a8f0eab8]{width:50%
}
}
.mirk-description .head-text[data-v-a8f0eab8]{color:#171519;text-align:center;font-weight:100;font-size:82px;font-family:Roboto,Helvetica,Arial,sans-serif;line-height:5.5vw;padding-bottom:2%;margin-top:150px
}
@media only screen and (min-width : 1024px) and (max-width: 1799px){
.mirk-description .head-text[data-v-a8f0eab8]{font-size:5vw
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.mirk-description .head-text[data-v-a8f0eab8]{font-size:42px;line-height:53px;margin-top:100px
}
}
.hr[data-v-a8f0eab8]{width:70px;text-align:center;display:block;height:7px;background-color:#3ce7af;border:0
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.hr[data-v-a8f0eab8]{width:61px;height:6px
}
}
.hero-video[data-v-a8f0eab8]{width:90%;max-width:1600px;margin:0 auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.hero-video[data-v-a8f0eab8]{display:none
}
}
@media only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.hero-video[data-v-a8f0eab8]{width:90%
}
}
.mobile-hero[data-v-a8f0eab8]{display:none
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.mobile-hero[data-v-a8f0eab8]{display:block
}
}
.video-container video[data-v-a8f0eab8]{margin-bottom:-12px
}
.video-container .services-video[data-v-a8f0eab8]{width:100%
}
.video-container .book-a-demo-container[data-v-a8f0eab8]{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;background-color:#6cbb43;padding:34px
}
.video-container .demo-btn[data-v-a8f0eab8]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;height:58px;width:208px;border-radius:30px;cursor:pointer;text-align:center
}
.video-container .demo-btn .text[data-v-a8f0eab8]{color:#6cbb43;text-align:center
}
.video-container .demo-btn .text[data-v-a8f0eab8]:hover{cursor:pointer
}
.video-container .demo-btn a[data-v-a8f0eab8]{width:100%;height:100%;vertical-align:middle;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}