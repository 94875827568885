
*[data-v-14ba370a]:focus{outline:none !important
}
.package-wrapper[data-v-14ba370a]{background:#E0F0C7
}
.package-wrapper .package-content[data-v-14ba370a]{padding-top:10vh;padding-bottom:10vh;width:90%;max-width:1600px;margin:0 auto;padding-left:5% !important
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.package-wrapper .package-content[data-v-14ba370a]{padding-bottom:0px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.package-wrapper .package-content[data-v-14ba370a]{width:92%;padding-left:0 !important;padding-bottom:0px
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px),only screen and (min-width : 1024px) and (max-width : 1365px){
.package-wrapper .package-content[data-v-14ba370a]{width:90%;padding-left:0 !important
}
}
.package-wrapper .package-content h1[data-v-14ba370a]{color:#072635;width:100%;font-size:55px;line-height:65px;font-family:Roboto,Helvetica,Arial,sans-serif;font-weight:700;margin-top:5vh;margin-bottom:15vh
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.package-wrapper .package-content h1[data-v-14ba370a]{font-size:32px;line-height:47px;margin-bottom:10vh
}
}
.package-wrapper .package-content p[data-v-14ba370a]{color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;margin-top:30px;margin-bottom:10vh
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.package-wrapper .package-content p[data-v-14ba370a]{margin-bottom:5vh
}
}
.package-wrapper .package-content img[data-v-14ba370a]{max-width:100%;object-fit:contain;margin-bottom:10vh
}
.package-wrapper .package-content .feature-logos[data-v-14ba370a]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.package-wrapper .package-content .feature-logos[data-v-14ba370a]{display:none
}
}
.package-wrapper .package-content .feature-logos-mob[data-v-14ba370a]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (min-width : 1024px) and (max-width: 1799px),only screen and (min-width: 1800px),only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px){
.package-wrapper .package-content .feature-logos-mob[data-v-14ba370a]{display:none
}
}