
*[data-v-d32f0f1c]:focus{outline:none !important
}
.package-content[data-v-d32f0f1c]{padding-top:10vh;padding-bottom:15vh;width:90%;max-width:1600px;margin:0 auto;padding-left:5% !important
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.package-content[data-v-d32f0f1c]{padding-bottom:0px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.package-content[data-v-d32f0f1c]{width:95%;padding-left:0 !important;padding-bottom:5vh;padding-top:5vh
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px),only screen and (min-width : 1024px) and (max-width : 1365px){
.package-content[data-v-d32f0f1c]{width:90%;padding-left:0 !important
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.package-content[data-v-d32f0f1c]{padding-top:5vh !important;padding-bottom:5vh !important
}
}
.package-content h1[data-v-d32f0f1c]{color:#072635;width:100%;font-size:55px;line-height:65px;font-family:Roboto,Helvetica,Arial,sans-serif;font-weight:700;margin-top:5vh;margin-bottom:5vh
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.package-content h1[data-v-d32f0f1c]{font-size:32px;line-height:47px;margin-bottom:5vh
}
}
.package-content .trusted-by[data-v-d32f0f1c]{color:#072635;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;margin-top:50px;margin-bottom:50px;max-width:550px
}