
*[data-v-a118cb52]:focus{outline:none !important
}
#navbar-mobile[data-v-a118cb52]{display:none
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
#navbar-mobile[data-v-a118cb52]{display:block
}
}
#navbar-desktop[data-v-a118cb52]{display:block
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
#navbar-desktop[data-v-a118cb52]{display:none
}
}
.mirk-navbar[data-v-a118cb52]{padding-bottom:20px;position:relative;top:0;left:0;width:100%;background:#072635;max-width:100vw
}
.mirk-navbar #navbar-desktop[data-v-a118cb52]{width:90%;max-width:1600px;padding-top:120px;margin:0px auto
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.mirk-navbar #navbar-desktop[data-v-a118cb52]{padding-top:10px;width:90%
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.mirk-navbar #navbar-desktop[data-v-a118cb52]{padding-top:20px;width:95%
}
}
@media only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.mirk-navbar #navbar-desktop[data-v-a118cb52]{padding-top:10px;width:95%
}
}
.spaces-plus-logo[data-v-a118cb52]{max-height:64px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.spaces-plus-logo[data-v-a118cb52]{max-width:40vw
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px),only screen and (min-width : 768px) and (max-width : 1023px){
.spaces-plus-logo[data-v-a118cb52]{max-height:50px
}
}
.nav-button span[data-v-a118cb52]{color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;font-weight:300
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px),only screen and (min-width : 660px) and (max-width : 1023px){
.nav-button span[data-v-a118cb52]{font-size:18px
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.nav-button span[data-v-a118cb52]{font-size:15px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.nav-button[data-v-a118cb52]{display:none
}
}
@media only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.nav-button[data-v-a118cb52]{display:block
}
}
.nav-button-get-started[data-v-a118cb52]{height:58px;width:190px;color:#16A7C9;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;font-weight:300;border:1px solid #16A7C9;border-radius:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.nav-button-get-started[data-v-a118cb52]{width:175px;margin-right:10px
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.nav-button-get-started[data-v-a118cb52]{height:50px
}
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.nav-button-get-started[data-v-a118cb52]{font-size:16px;height:50px;width:170px
}
}
.nav-button-book-demo[data-v-a118cb52]{height:58px;width:170px;color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:18px;font-weight:300;border:1px solid #72BF00;background:#72BF00;border-radius:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-right:15px
}
@media only screen and (min-width : 1024px) and (max-width : 1365px){
.nav-button-book-demo[data-v-a118cb52]{font-size:16px;height:50px;width:170px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.nav-button-book-demo[data-v-a118cb52]{display:none
}
}
.nav-button-book-demo a[data-v-a118cb52]{color:white
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px){
.nav-button-book-demo[data-v-a118cb52]{height:50px;width:150px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.brand-logo[data-v-a118cb52]{padding-right:10px;padding-left:10px;width:49% !important;max-width:49% !important;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center !important;justify-content:center !important
}
.brand-logo .justify-content-between[data-v-a118cb52]{-ms-flex-pack:center !important;justify-content:center !important;-ms-flex-align:center;align-items:center
}
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.brand-logo[data-v-a118cb52]{width:60% !important;max-width:60% !important;padding-right:20px;padding-left:20px
}
}
@media only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px){
.brand-logo[data-v-a118cb52]{padding-right:10px;padding-left:10px
}
}