
*[data-v-790244cf]:focus{outline:none !important
}
.feature-wrapper[data-v-790244cf]{background:#072635
}
.feature-wrapper .feature-content[data-v-790244cf]{padding-top:10vh;padding-bottom:10vh;width:90%;max-width:1600px;margin:0 auto;padding-left:5% !important
}
@media only screen and (min-width : 660px) and (max-width : 1023px){
.feature-wrapper .feature-content[data-v-790244cf]{padding-bottom:0px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.feature-wrapper .feature-content[data-v-790244cf]{width:90%;padding-left:0 !important;padding-bottom:0px
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 1200px) and (max-width: 1300px) and (min-height: 800px) and (max-height: 900px),only screen and (min-width : 1024px) and (max-width : 1365px){
.feature-wrapper .feature-content[data-v-790244cf]{width:90%;padding-left:0 !important
}
}
@media only screen and (min-width : 768px) and (max-width : 1023px){
.feature-wrapper .feature-content[data-v-790244cf]{padding-top:5vh
}
}
.feature-wrapper .feature-content h1[data-v-790244cf]{color:#72BF00;width:100%;font-size:55px;line-height:65px;font-family:Roboto,Helvetica,Arial,sans-serif;font-weight:700;margin-top:5vh
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px),only screen and (min-width : 660px) and (max-width : 1023px){
.feature-wrapper .feature-content h1[data-v-790244cf]{font-size:32px;line-height:47px
}
}
.feature-wrapper .feature-content p[data-v-790244cf]{color:white;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;margin-top:30px;margin-bottom:10vh;max-width:500px
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.feature-wrapper .feature-content p[data-v-790244cf]{margin-bottom:5vh
}
}
.feature-wrapper .feature-content img[data-v-790244cf]{max-width:100%;object-fit:contain;margin-bottom:10vh
}
.feature-wrapper .feature-content .feature-logos[data-v-790244cf]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.feature-wrapper .feature-content .feature-logos[data-v-790244cf]{display:none
}
}
.feature-wrapper .feature-content .feature-logos-mob[data-v-790244cf]{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
@media only screen and (min-width : 1024px) and (max-width: 1799px),only screen and (min-width: 1800px),only screen and (min-width : 768px) and (max-width : 1023px),only screen and (min-width : 660px) and (max-width : 1023px),only screen and (min-width : 1024px) and (max-width : 1365px){
.feature-wrapper .feature-content .feature-logos-mob[data-v-790244cf]{display:none
}
}