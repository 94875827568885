
*:focus{outline:none !important
}
.banner-text-container{width:100%;box-sizing:border-box;overflow:hidden;background-color:#72BF00;position:relative;margin-top:80px;-ms-flex-pack:center;justify-content:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-text-container{margin-top:50px
}
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-text-container p{line-height:20px;margin-bottom:.3em
}
}
.banner-text-container .banner-text-wrapper{max-width:980px;min-height:400px;padding:100px 0;width:100%;margin:0 auto;text-align:center;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center
}
.banner-text-container .banner-text-wrapper:hover a{text-decoration:none
}
@media only screen and (max-width : 392px){
.banner-text-container .banner-text-wrapper{padding:70px 0
}
}
.banner-text-container .banner-text{width:90%;max-width:1600px;margin:0 auto;color:#FFFFFF;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:33px;font-weight:500;line-height:45px;text-align:center
}
@media only screen and (max-width : 392px),only screen and (min-width : 393px) and (max-width : 659px){
.banner-text-container .banner-text{font-size:18px;line-height:30px;padding-left:0% !important
}
}